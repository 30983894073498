import { useRef, useEffect } from 'react';
import axios from '../api/axios';
import authHeader from './auth-header';
import { serialize } from 'object-to-formdata';

export const requestNetwork = (async (api, data, istoken, exData = null) => {
    try{
        
        const reSponse = await axios.post('', serialize(data, {indices: true}, exData), {
            params: {
                'API': api,
            },
            withCredentials: false,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data',
                'Client-Api': 'PLATFORM',
                'Client-Os': 'WEB',
                'Client-Type': 'ADMIN',
                'Client-Language': 'EN',
                'Client-Bundle': 'com.lecastella.webadmin',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
                'Access-Control-Allow-Credentials': true,
                'Token': istoken ? authHeader() : ''
            }
        });
        
        console.log(reSponse);
        if(reSponse.data.error !== "0"){
            if(reSponse.data.error === "101"){
                localStorage.removeItem("token");
                localStorage.removeItem("user");

                return {error: 101, message: reSponse.data.message, data: reSponse.data.data};
            }else{
                return {error: parseInt(reSponse.data.error), message: reSponse.data.message, data: reSponse.data.data};
            }
        }else{
            return {error: 0, message: reSponse.data.message, data: reSponse.data.data};
        }
    } catch (err){
        if(!err.response){
            return {error: 1, message: "No server response", data: null};
        }else if(err.response.status === "400") {
            return {error: 1, message: "Missing", data: null};
        }else{
            return {error: 1, message: "Connection Failed", data: null};
        }
    }
});

// export const requestDownload = (async (api, data, istoken, name) => {
//     try{
//         const reSponse = await axios.post('', data, {
//             params: {
//                 'API': api,
//             },
//             withCredentials: false,
//             headers: {
//                 'Content-Type': 'multipart/form-data',
//                 'Client-Api': 'PARTNER',
//                 'Client-Os': 'WEB',
//                 'Client-Language': 'EN',
//                 'Token': istoken ? authHeader() : ''
//             },
//             responseType: "blob"
//         });
        
//         const file_name = name+"_"+data.start+"_to_"+data.finish+"_";
//         const url = window.URL.createObjectURL(new Blob([reSponse.data]));
//         const link = document.createElement('a');
//         link.href = url;
//         link.setAttribute('download', file_name+'.xls'); //or any other extension
//         document.body.appendChild(link);
//         link.click();
//     } catch (err){
//         if(!err.response){
//             return {error: 1, message: "No server response", data: null};
//         }else if(err.response.status === "400") {
//             return {error: 1, message: "Missing", data: null};
//         }else{
//             return {error: 1, message: "Login Failed", data: null};
//         }
//     }
// });

export const useEffectOnce = ( effect => {

    const destroyFunc = useRef();
    const calledOnce = useRef(false);
    const renderAfterCalled = useRef(false);

    if (calledOnce.current) {
        renderAfterCalled.current = true;
    }

    useEffect( () => {
        if (calledOnce.current) { 
            return; 
        }

        calledOnce.current = true;
        destroyFunc.current = effect();

        return ()=> {
            if (!renderAfterCalled.current) {
                return;
            }

            if (destroyFunc.current) {
                destroyFunc.current();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
});