import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, Navigate, useNavigate, Outlet } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box, Stack, LinearProgress, Grid, Tab, Tabs, Typography, Button, useMediaQuery, Toolbar, Container } from '@mui/material';
import { requestNetwork } from 'services/http-service';
import useAuth from "../hooks/useAuth";
import error500 from '../assets/images/Error500.png';
import MenuBar from './widgets/Menubar';
import LoadingButton from '../components/LoadingButton';


const MainLayout = () => {
    const theme = useTheme();
    const auth = useAuth();
    const location = useLocation();
    const dispatch = useDispatch();
    const matchDownLG = useMediaQuery(theme.breakpoints.down('xl'));
    var navigate = useNavigate();
    const [ authentication, setAuthentication ] = useState(true);
    const [ isError, setIsError ] = useState(false);

    const [isSubmiting, setIsSubmiting] = useState(false);
    const signoutHandler = (async () => {
        auth.logout();
        navigate("/login", {replace: true});
    });

    useEffect(() => {
        if(auth.token() === null || auth.token() === "undefined"){
            localStorage.removeItem("token");
            localStorage.removeItem("user");

            navigate("/login", { replace: true });
        }else{
            fetchAuth();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownLG]);

    const fetchAuth = (async () => {
        setAuthentication(true);

        requestNetwork('admin/home/authentication', { }, true).then((res) => {
            if(res.error === 101){
                navigate("/login", {replace: true});
            }else{
                if(res.error === 0){
                    setAuthentication(false);
                    setIsError(false);
                    navigate("");
                }else{
                    setIsError(true);
                    setAuthentication(false);
                }
            }
        }).catch((err)=>{
            setIsError(true);
            setAuthentication(false);
        });
    });

    return (
        (auth.token() !== null || auth.token() !== "undefined") ? <>
            {(authentication) ? <Stack direction="column" spacing={0}>
                <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ width: '100%'}}>
                        <LinearProgress />
                    </Box>
                </Box>
                <div style={{margin: 'auto auto'}}>
                    Authentication
                </div>
            </Stack> : 
                <div style={{margin: 'auto auto', width: '100%', minHeight: '100vh'}}>
                    {(isError) ? <>
                        <Grid container direction="column" alignItems="center" justifyContent="center" sx={{ minHeight: '100vh' }}>
                            <Grid item xs={12}>
                                <Box sx={{ width: { xs: 350, sm: 396 } }}>
                                    <img src={error500} alt="mantis" style={{ height: '100%', width: '100%' }} />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack justifyContent="center" alignItems="center">
                                    <Typography align="center" variant={matchDownLG ? 'h2' : 'h1'}>
                                        Internal Server Error
                                    </Typography>
                                    <Typography color="textSecondary" variant="body2" align="center" sx={{ width: { xs: '73%', sm: '70%' }, mt: 1 }}>
                                        Server error 500. we fixing the problem. please try again at a later stage.
                                    </Typography>
                                    <Button onClick={() => { fetchAuth(); }} disabled={authentication} variant="contained" sx={{ textTransform: 'none', mt: 4 }}>
                                        Retry
                                    </Button>
                                </Stack>
                            </Grid>
                        </Grid>
                    </> : 
                        <div className="bg_02">
                        {/* <div style={{backgroundColor: '#FFFFFF'}}> */}
                            <Box sx={{ position: 'relative', backgroundColor: '#FFFFFF', height: '100vh'}}>
                                <Box sx={{ display: 'flex', width: '100%', backgroundColor: 'rgba(255, 255, 255, 0.3)' }}>
                                    <Box component="main" sx={{ width: `100%`, flexGrow: 1, p: { xs: 0, sm: 0 } }}>
                                        <Toolbar style={{backgroundColor: 'black', paddingRight: '12px', paddingLeft: '12px', display: "block", width: "100%"}}>
                                            <Stack direction="row" alignItems='center' justifyContent='space-between' >
                                                {/* <Stack direction="row" alignItems='center' justifyContent='flex-start' spacing={2}>
                                                    <Button variant="contained" sx={{height: '40px', marginLeft: '10px'}} color='success' onClick={()=> { navigate("/"); }} ><b>HOME</b></Button>
                                                    <Button variant="contained" sx={{height: '40px', marginLeft: '10px'}} color='warning' onClick={()=> { navigate("/tk"); }} ><b>សាខា TK</b></Button>
                                                </Stack> */}
                                                <Box sx={{position: "relative", display: 'block'}}>
                                                    <MenuBar />
                                                </Box>
                                                <Stack direction="row" alignItems='center' justifyContent='flex-end' spacing={2}>
                                                    <LoadingButton sx={{height: '44px'}} color='error' loading={isSubmiting} fullWidth disabled={isSubmiting} variant="contained" size="small" onClick={signoutHandler} >
                                                        <b style={{fontSize: '18px'}}>ចាកចេញ</b>
                                                    </LoadingButton>
                                                </Stack>
                                            </Stack>
                                        </Toolbar>
                                        <Container maxWidth="xlg" sx={{ px: { xs: 0, sm: 0 }, position: 'relative', minHeight: 'calc(100vh - 60px)', display: 'flex', flexDirection: 'column'}} >
                                            <Outlet />
                                        </Container>
                                    </Box>
                                </Box>
                            </Box>
                        </div>
                    }
                </div>
            }
        </> : <Navigate to="/login" state={{ from: location }} replace />
    );
}

export default MainLayout