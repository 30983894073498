import React, { createContext, useState } from "react";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState(null);

    const setUser = (user) => {
        localStorage.setItem("user", JSON.stringify(user));
        setAuth(user);
    }

    const user = () => {
        var u = localStorage.getItem("user");
        if (typeof u === 'string') {
            return JSON.parse(u);
        }
        return null;
    }

    const logout = () => {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        setAuth(null);
    };

    const setToken = (t) => {
        localStorage.setItem("token", t);
        setAuth(t);
    }

    const token = () => {
        var t = localStorage.getItem("token");
        
        if (typeof t === 'string') {
            return t;
        }
        return null;
    }

    return (
        <AuthContext.Provider value={{ setUser, setToken, user, token, logout, auth }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;