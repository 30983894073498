export const drawerWidth = 260;
export const miniWidth = 48;

const config = {
    defaultPath: '/',
    fontFamily: `'Public Sans', sans-serif`,
    i18n: 'en',
    miniDrawer: true,
    container: false,
    mode: 'dark',
    presetColor: 'default',
    themeDirection: 'ltr',
};

export default config;