import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, Container, Paper, Stack, TextField, Button, CircularProgress, InputAdornment, IconButton, OutlinedInput, Typography } from '@mui/material';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { requestNetwork } from 'services/http-service';
import useAuth from '../hooks/useAuth';
//import AppLogo from '../assets/images/bloclogo.png';

const LoginScreen = () => {
    const setAuth = useAuth();
    const navigate = useNavigate();

    const errRef = useRef();
    
    const [username, setUsername] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [isLoading, setLoading] = useState(false);
    
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    useEffect(() => {
        setErrMsg('');
    }, [username, pwd]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        requestNetwork('admin/passport/login', {
            username: username,
            passwd: pwd
        }, false).then(async (res) => {
            if(res !== "undefined" && res.error === 0 && res.data && res.data.user && res.data.token){
                setUsername('');
                setPwd('');

                await setAuth.setUser(res.data.user);
                await setAuth.setToken(res.data.token);

                navigate("/", {replace: true});
            }else{
                setErrMsg(res.message);
            }
            setLoading(false);
        }).catch((err)=>{
            setLoading(false);
            setErrMsg(err);
        });
    };

    return (
        <Box className="bg_login">
            <Stack direction="column" style={{position: "absolute", left: '40px', top: '20px', display: "block"}}>
                <Typography variant="h3" sx={{ textDecoration: 'none' }} style={{color: '#000000'}}>
                    LE CASTELLA - Administrator Service
                </Typography>
                <Typography variant="h5" sx={{ textDecoration: 'none' }} style={{color: '#000000'}}>
                    Version: 1.0 (2023)
                </Typography>
            </Stack>
            <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '100vh' }}>
                <Grid item xs={12} container justifyContent="center" alignItems="center" sx={{ minHeight: { xs: 'calc(100vh - 210px)', sm: 'calc(100vh - 210px)', md: 'calc(100vh - 210px)' } }}>
                    <Container className="container" maxWidth="xs">
                        <Grid item style={{ display: "flex", alignItems: "center", height: '100vh'}}>
                            <Paper elevation={10} style={{padding: '20px', width: '420px'}}>
                                <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                                <form onSubmit={handleSubmit}>
                                    <Grid align='center'>
                                        {/* <img src={AppLogo} style={{height: 80, width: 200}} alt="Logo"/> */}
                                        <h2>Sign In</h2>
                                    </Grid>
                                    <Stack direction="row" spacing={2}>
                                        <TextField id="username" hiddenLabel placeholder='Enter Username' fullWidth autoComplete="off" onChange={(e) => setUsername(e.target.value)} value={username} required/>
                                    </Stack>
                                    <div style={{height: '20px'}}/>
                                    <OutlinedInput placeholder="Enter Password" id="password"
                                        type={showPassword ? 'text' : 'password'}
                                        value={pwd}
                                        onChange={(e) => setPwd(e.target.value)}
                                        name="current"
                                        fullWidth
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                    size="small"
                                                    color="secondary" >
                                                    {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        inputProps={{}}
                                    />
                                    <div style={{height: '20px'}}/>
                                    <Button type='submit' size="large" sx={{height: '56px'}} disableElevation color='primary' variant="contained" fullWidth disabled={isLoading}>{isLoading ? <CircularProgress
                                        color="secondary" /> : 'Sign in'}
                                    </Button>
                                </form>
                            </Paper>
                        </Grid>
                    </Container>
                </Grid>
            </Grid>
        </Box>
    );
};

export default LoginScreen