
import React, { lazy, Suspense } from 'react';
import Loadable from './components/Loadable';

import { Routes, Route, Navigate } from 'react-router-dom';
import MainLayout from './pages/MainLayout';
import MissingScreen from './pages/Missing';
import LoginScreen from './pages/LoginScreen';
import './App.css';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ThemeCustomization from './themes'
// screen
const HomeScreen = Loadable(lazy(() => import('./pages/HomeScreen')));
const DashboardScreen = Loadable(lazy(() => import('./pages/home/DashboardScreen')));
const CategoryScreen = Loadable(lazy(() => import('./pages/home/warehouses/CategoryScreen')));
const ProductScreen = Loadable(lazy(() => import('./pages/home/warehouses/ProductScreen')));
// Branch layout
const BranchLayout = Loadable(lazy(() => import('./pages/home/branch/BranchLayout')));
// Shop layout
const ShopLayout = Loadable(lazy(() => import('./pages/home/shop/ShopLayout')));
const ProductionLayout = Loadable(lazy(() => import('./pages/home/productions/ProductionLayout')));
const UserTerminalLayout = Loadable(lazy(() => import('./pages/home/terminals/UserTerminalLayout')));
const StaffLayout = Loadable(lazy(() => import('./pages/home/staffs/StaffLayout')));

function App() {
    return (
        <ThemeCustomization>
            <Suspense fallback={<div className="container">Loading...</div>}>
                
                <Routes>
                    <Route path="login" element={<LoginScreen />} />
                    <Route path='/' element={<MainLayout />}>
                        <Route index element={<Navigate to="/home" />} />
                        <Route path="home" element={<HomeScreen />} >
                            <Route index element={<DashboardScreen />} />
                            <Route path="dashboard" element={<DashboardScreen />} />
                            <Route path="category" element={<CategoryScreen />} />
                            <Route path="product" element={<ProductScreen />} />
                            <Route path="shop" element={<ShopLayout />} />
                            <Route path="production" element={<ProductionLayout />} />
                            <Route path="terminal" element={<UserTerminalLayout />} />
                            <Route path="staff" element={<StaffLayout />} />
                        </Route>
                        <Route path="branch/:shopId" element={<BranchLayout />} />
                    </Route>
                    <Route path="*" element={<MissingScreen />} />
                </Routes>
            </Suspense>
            <ToastContainer position="top-center" autoClose={1000}/>
        </ThemeCustomization>
    );
}

export default App;
