import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { AuthProvider } from "./context/AuthProvider";
import { storage } from './redux';
import './index.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    //<React.StrictMode> //turn off for sinble call for useEffect
        <ReduxProvider store={storage}>
            <AuthProvider>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </AuthProvider>
        </ReduxProvider>
    //</React.StrictMode>
);