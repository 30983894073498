import React from 'react';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';

// material-ui
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import config from '../config';
import Palette from './palette';
import CustomShadows from './shadows';
import Typography from './typography';

export default function ThemeCustomization({ children }) {
    const { themeDirection } = useState(config.themeDirection);
    const { mode } = useState(config.mode);
    const { presetColor } = useState(config.presetColor);
    const { fontFamily } = useState(config.fontFamily);

    const theme = useMemo(() => Palette(mode, presetColor), [mode, presetColor]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const themeTypography = useMemo(() => Typography(fontFamily), [fontFamily]);
    const themeCustomShadows = useMemo(() => CustomShadows(theme), [theme]);


    const themeOptions = {
        breakpoints: {
            values: {
            xs: 0,
            sm: 768,
            md: 1024,
            lg: 1266,
            xl: 1536
            }
        },
        direction: themeDirection,
        mixins: {
            toolbar: {
            minHeight: 60,
            paddingTop: 8,
            paddingBottom: 8
            }
        },
        palette: theme.palette,
        customShadows: themeCustomShadows,
        typography: themeTypography
    }

    const themes = createTheme(themeOptions);
    //themes.components = componentsOverride(themes);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes}>
                <CssBaseline />
                {children}
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

ThemeCustomization.propTypes = {
    children: PropTypes.node
};