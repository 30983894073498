import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { requestNetwork } from 'services/http-service';

function MenuBar ({}) {
    var navigate = useNavigate();

    const [items, setItems] = useState(
        [
            { label: 'Home', link: '/', active: true },
        ]
    );

    const onHandleClick = d => {
        items.forEach(b => (b.active = false))
        d.active = true
        setItems([...items])
        navigate(d.link);
    }

    const fetchShopList = (async () => {
        requestNetwork('admin/home/shop', { }, true).then((res) => {
            if(res.error === 0 && res.data !== undefined){
                if (Array.isArray(res.data) && res.data.length > 0) {
                    for(let i = 0; i < res.data.length; i++){
                        items.push({
                            shop_id: res.data[i].shop_id,
                            label: res.data[i].title,
                            link: '/branch/'+res.data[i].shop_id,
                            active: false
                        });
                    }
                }
                setItems([...items]);
            }
        }).catch((err)=>{ });
    });

    useEffect(() => {
        fetchShopList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box className="navbar">
            <ul>
                 {items.map(d => (
                     <li onClick={(e) => onHandleClick(d)} className={d.active ? 'list-item active' : 'list-item'} key={d.label} >
                        <a >
                        {d.label}
                        </a>
                     </li>
                 ))}
            </ul>
        </Box>
        
    );
}
  
export default MenuBar